// Load jQuery
var onLoad;

import $ from 'jquery';

// Load Popper
require('popper.js');

// Load Bootstrap
require('bootstrap');

import Rails from '@rails/ujs';

Rails.start();

import "@hotwired/turbo-rails";

onLoad = function() {
  return $('.alert:not(.dont-dismiss)').delay(3000).slideUp(200, function() {
    return $(this).alert('close');
  });
};

$(document).on('turbo:load', onLoad);

import 'styles/concerto_back_office/devise.scss';
